<template>
  <div :style="$store.style">
    <header
      v-if="$store.type == 'page'"
      :class="{ image: $store.json.image_url }"
      class="page"
    >
      <video
        v-if="$store.header.video"
        muted
        autoplay="true"
        loop="true"
        :src="$store.header.video"
      />
      <img
        v-if="!$store.header.video && $store.json.image_url"
        class="bgImage"
        :src="$store.json.image_url"
      />

      <div class="content">
        <div class="flex items-center mb-4">
          <div
            ref="tag"
            v-if="
              $store.category && !$store.config.disable.includes('category')
            "
            class="tag category mr-4"
            @click="switchHeader()"
          >
            <img
              v-if="
                ($store.category.uploaded_background &&
                  $store.json.image_url) ||
                $store.header.video
              "
              :src="getLogo($store.category.uploaded_background.url)"
            />
            <img
              v-if="
                $store.category.uploaded_logo &&
                !$store.json.image_url &&
                !$store.header.video
              "
              :src="getLogo($store.category.uploaded_logo.url)"
            />
            <span>{{ $store.category.name }}</span>
          </div>
        </div>

        <a
          class="title"
          target="_blank"
          :href="'https://edgeryders.eu/t/' + $store.id"
          >{{ $store.json.title }}</a
        >
        <div
          v-if="$store.header.text"
          v-html="$store.header.text"
          class="header_text"
        ></div>
        <div class="page_links" v-if="$store.header.links">
          <a
            class="button"
            :style="'color: #' + $store.category.color"
            v-for="(item, index) in $store.header.links"
            :href="item.url"
            :key="index"
            >{{ item.text }}</a
          >
        </div>
      </div>
      <div class="logos" v-if="$store.logos">
        <img
          v-for="(item, index) in $store.logos.content"
          :key="index"
          :src="item.image"
        />
      </div>
      <a
        v-if="!$store.config.disable.includes('discourse')"
        class="page_url"
        :href="'https://edgeryders.eu/t/' + $store.id"
        >Read on Platform
      </a>
    </header>

    <header
      class="event"
      v-if="$store.type == 'event'"
      :class="{ image: $store.json.image_url }"
      :style="'background: #' + $store.category.color"
    >
      <div class="wrapper">
        <div class="text">
          <div class="flex mb-4 items-center">
            <div
              ref="tag"
              v-if="$store.category"
              class="tag category mr-4"
              @click="switchHeader()"
            >
              <img
                v-if="$store.category.uploaded_background"
                :src="getLogo($store.category.uploaded_background.url)"
              />
              <span>{{ $store.category.name }}</span>
            </div>
          </div>
          <h2 class="date">{{ $store.metadata.event.start | dateLong }}</h2>
          <h2>{{ $store.metadata.title }}</h2>

          <scrollactive :offset="80">
            <a class="register scrollactive-item" href="#register"
              >Register now</a
            >
          </scrollactive>
        </div>
      </div>
      <div class="image">
        <div class="home_menu"></div>
        <img
          v-if="$store.json.image_url"
          :src="$store.json.image_url"
          alt="event image"
        />
      </div>
      <a class="page_url" :href="'https://edgeryders.eu/t/' + $store.id"
        >Read on Platform</a
      >
    </header>

    <header
      class="event news"
      v-if="$store.type == 'news'"
      :class="{ noimage: !$store.json.image_url }"
    >
      <div class="wrapper">
        <div class="text">
          <div class="flex items-center">
            <div ref="tag" class="tag category mr-4" @click="switchHeader()">
              <img
                v-if="$store.category.uploaded_background"
                :src="getLogo($store.category.uploaded_logo.url)"
              />
              <span>{{ $store.category.name }}</span>
            </div>
          </div>
          <h2 class="mt-5 border-b pb-6">{{ $store.metadata.title }}</h2>
          <div class="w-full flex">
            <div class="author_badge">
              <img
                :src="
                  'https://edgeryders.eu' +
                  $store.metadata.author.avatar_template.replace('{size}', 200)
                "
              />
              <div class="flex flex-col">
                <h2>
                  {{
                    $store.metadata.author.name ||
                    "@" + $store.metadata.author.username
                  }}
                </h2>
                <p>{{ $store.metadata.date | dateLong }}</p>
              </div>
            </div>

            <div class="news_meta">
              <p>{{ readTime($store.post).count }} Words</p>
              <p>{{ readTime($store.post).time }} minute read</p>
            </div>
          </div>
        </div>
      </div>
      <div class="image">
        <div class="home_menu"></div>
        <img
          v-if="$store.json.image_url"
          :src="$store.json.image_url"
          alt="event image"
        />
      </div>
      <a class="page_url" :href="'https://edgeryders.eu/t/' + $store.id"
        >Read on Platform</a
      >
    </header>

    <header v-if="$store.type == 'project'" class="project">
      <div
        style="width: 100%"
        class="wrapper flex mx-auto items-center"
        v-if="$store.category"
      >
        <div class="text">
          <h2 class="mb-6 border-b pb-6">{{ $store.category.name }}</h2>
          <p v-html="getDescription($store.category.description)"></p>
          <div class="button_list">
            <a class="button subscribe" @click="$refs.modalName.openModal()"
              >Subscribe</a
            >
            <a
              class="button forum"
              :href="'https://edgeryders.eu/c/' + $store.category.id"
              target="_blank"
              >Forum</a
            >
            <a class="button contact" href="#footer"> Contact Us</a>
          </div>
        </div>

        <div class="sliced">
          <div
            class="logo"
            v-if="$store.category.uploaded_logo"
            :style="
              'background-image: url(' +
              getLogo($store.category.uploaded_logo.url) +
              ')'
            "
          ></div>
        </div>
      </div>
    </header>
    <modal ref="modalName">
      <template v-slot:header>
        <h1 v-if="!success">
          Subscribe to <b>{{ $store.category.name }}</b>
        </h1>
      </template>

      <template v-slot:body>
        <p v-if="!success">
          Stay notified of news and upcoming events in this project. You will
          receive only occasional updates relating to this category.
        </p>
        <FormulateForm
          v-if="!success"
          class="register_form"
          v-model="formValues"
          @submit="submitHandler"
          #default="{ hasErrors }"
        >
          <FormulateInput
            name="name"
            type="text"
            label=""
            placeholder="Your name"
            validation="required"
          />
          <FormulateInput
            name="email"
            type="email"
            label=""
            placeholder="Your email address"
            validation="email"
          />
          <FormulateInput
            name="comment"
            type="textarea"
            label=""
            placeholder="Additional comments."
          />
          <FormulateInput
            type="submit"
            label="Register"
            :disabled="hasErrors"
          />
        </FormulateForm>
        <div class="register_form" v-if="success">
          <div class="confirmation">
            <h1 class="border-b mb-4 pb-4">Thank you for subscribing!</h1>
            <p class="text-lg">
              You can join the community discussion
              <a
                target="_blank"
                class="underline"
                :href="'http://edgeryders.eu/c/' + $store.category.id"
                >here</a
              >.
            </p>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import modal from "../elements/modal.vue";
export default {
  name: "Header",
  props: {},
  data() {
    return {
      slice: false,
      email: false,
      terms: false,
      success: false,
      formValues: {},
    };
  },
  components: {
    modal,
  },
  methods: {
    async submitHandler(data) {
      var obj = data;
      obj.category = this.$store.category.slug;
      this.sendBaserow(obj, "category");
      this.success = true;
    },
    switchHeader() {
      this.$store.blob.start = true;
      this.goTo("project", this.$store.category.slug);
    },
    readTime(value) {
      var element = document.createElement("div");
      element.innerHTML = value;
      var wpm = 230;
      var word_count = element.innerText.split(" ").length;
      var obj = {
        count: word_count,
        time: Math.round(word_count / wpm),
      };
      return obj;
    },
    getBlob() {
      const left = this.$refs.tag.getBoundingClientRect().left;
      const top = this.$refs.tag.getBoundingClientRect().top;
      const height = this.$refs.tag.getBoundingClientRect().height;
      const width = this.$refs.tag.getBoundingClientRect().width;
      var obj = {
        active: true,
        left: left,
        start: false,
        top: top,
        height: height,
        width: width,
        color: "#" + this.$store.category.color,
      };
      this.$store.blob = obj;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.register_form {
  @apply mx-auto;
  width: 90%;
  /deep/ button {
    @apply px-4 py-2 mt-3 mx-auto rounded-full inline-block;
    background: #000;
    color: white;
  }
}

input {
  @apply border-b pt-4 pb-2;
  &:focus {
    outline: none;
    border-color: black;
  }
}
div /deep/ input {
  @apply pt-2 font-bold px-1 pb-3 mt-4 border-b w-full mx-auto;
  &:focus {
    outline: none;
    border-color: black;
  }
}
div /deep/ textarea {
  @apply pt-2 font-bold px-1 pb-3 mt-4 border-b w-full mx-auto;
  &:focus {
    outline: none;
    border-color: black;
  }
}
div /deep/ .formulate-input-errors {
  color: orangered !important;
  @apply py-3 px-2;
}
header {
  @apply w-full flex bg-cover pt-20 pb-0;
  min-height: 300px;
  height: auto;
  background: rgba(0, 0, 0, 0.02);
  .info {
    width: 300px;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
  }
  &.page {
    @apply mb-10;
    .tag {
      border-radius: 100px;
      margin-left: -10px;
      @apply mb-2;
      border: 1px solid rgba(161, 161, 161, 0.3);
      padding: 10px 18px;
      color: rgb(0, 0, 0);
      background: rgba(255, 255, 255, 0.2);
    }
  }
  &.page.image {
    background: black;

    @apply block relative items-center justify-center  leading-tight mb-12;
    height: auto;
    min-height: 400px;
    padding-top: 60px;
    padding-bottom: 0px;
    border-bottom: none;
    margin-top: 0px;
    background-size: cover !important;
    background-position: 50% 50% !important;
    overflow: hidden;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   height: 130%;
    //   background: linear-gradient(
    //     180deg,
    //     rgba(255, 255, 255, 1) 0%,
    //     rgba(255, 255, 255, 0) 100%
    //   );

    //   width: 100%;
    // }
    &:after {
      content: "";
      position: absolute;
      bottom: -100px;
      height: 90%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );

      width: 100%;
    }
    .tag {
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 10px 16px;
      color: white;
      background: rgba(0, 0, 0, 0);
    }
    .content {
      color: #fff;
      margin-top: 10rem;
      width: 70%;
      text-shadow: 0 2px 0px rgba(0, 0, 0, 0.9);
      .header_text {
        font-size: 1.3rem;
      }
    }
  }
  .page_url {
    z-index: 99999;
    border-radius: 100px;
    color: white;
    position: absolute;
    bottom: 15px;
    right: 20px;
    padding: 10px 17px 10px;
    font-size: 14px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cpath fill='white' d='M75.4 58.1L88 45.5a23.7 23.7 0 00-33.6-33.6L36.2 30.2a23.7 23.7 0 004.5 37l9.8-9.8c-1.9-.4-3.7-1.3-5.1-2.8-4.2-4.2-4.2-11 0-15.2L63.7 21a10.8 10.8 0 0115.2 15.2l-5.4 5.4A29 29 0 0175.5 58z'/%3E%3Cpath fill='white' d='M24.6 41.9L12 54.5a23.7 23.7 0 0033.6 33.6l18.3-18.3a23.7 23.7 0 00-4.5-37l-9.8 9.8c1.9.4 3.7 1.3 5.1 2.8 4.2 4.2 4.2 11 0 15.2L36.3 79a10.8 10.8 0 01-15.2-15.2l5.4-5.4A29 29 0 0124.6 42z'/%3E%3C/svg%3E")
      no-repeat 16px 60% rgba(0, 0, 0, 0.7);
    background-size: 15px;
    @apply font-bold;
    padding-left: 40px;
    &:hover {
      @apply underline;
    }
  }
  .logos {
    z-index: 99999;
    color: white;
    position: absolute;
    bottom: 0px;
    right: 230px;
    img {
      width: auto;
      height: 120px;
    }
  }
  .content {
    @apply absolute flex text-left mx-auto  flex-col justify-center items-start;
    z-index: 10;
    bottom: 60px;
    max-width: 1020px;
    a.title {
      @apply text-3xl font-bold;
      &:hover {
        @apply underline;
      }
    }
    h2 {
      @apply text-4xl font-light mb-4;
      max-width: 500px;
    }
  }
}

header.news {
  @apply border-b pt-0;
  margin-top: 60px !important;
  .tag {
    border: 1px solid #ddd !important;
    @apply ml-0 inline-flex border;
    margin-bottom: 0 !important;
    white-space: nowrap;
    color: black;
    border-radius: 100px !important;
    @apply text-xl leading-tight tracking-tight;
    padding: 9px 12px !important;
    font-size: 15px;
    img {
      height: 21px;
      @apply mr-2;
    }
  }
  &.noimage {
    border-bottom: none;
  }
  .wrapper {
    margin-top: 60px !important;
    .text {
      h2 {
        @apply text-base;
      }
      .author_badge {
        @apply flex items-center font-bold pb-2 pr-5 text-white text-2xl;
        border-right: 0.5px solid rgba(0, 0, 0, 0.1);
        img {
          @apply rounded-full mr-4;
          height: 55px;
          width: 55px;
        }
        h2 {
          @apply m-0 p-0 text-base;
          margin-bottom: 0 !important;
        }
        p {
          @apply m-0 p-0 text-base;
          margin: 0 !important;
          color: var(--color);
        }
      }
      .news_meta {
        @apply flex flex-col items-start justify-center pl-6 font-bold pb-2 text-white text-2xl;

        p {
          font-size: 1rem !important;
          @apply font-bold leading-tight;
          color: var(--color);
          margin: 0;
          padding: 0;
          &:first-child {
            color: black;
          }
        }
      }
    }
  }
}

header.event {
  overflow: hidden;
  height: auto;
  min-height: 600px;
  position: relative;
  @apply flex mb-14 items-center;
  .tag {
    background: rgba(255, 255, 255, 0.1);
  }
  .wrapper {
    @apply relative flex px-8 mt-0 mx-auto items-center justify-start w-full h-full;
    z-index: 9999;
    max-width: 1280px;
    .text {
      width: 42%;
      h2 {
        @apply mb-5 font-sans text-3xl font-bold tracking-tight leading-tight text-black;
        &.date {
          opacity: 0.9;
          @apply text-white;
        }
      }

      p {
        @apply pr-5 mb-5 text-xl text-white;
      }
      a.action {
        @apply px-6 py-3 mr-6 text-base font-bold rounded-full shadow-md bg-white;
        white-space: nowrap;
      }
    }
  }

  .image {
    @apply inset-y-0 top-0 right-0 z-0 w-full absolute;
    width: 50%;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    img {
      @apply object-cover w-full h-full;
    }
  }
}

.formulate-input-wrapper {
  label {
    @apply font-bold text-lg mb-3;
  }
  input {
    @apply w-2/3 p-2 px-3 bg-white border mt-1;
  }
  .formulate-input-element--submit button {
    color: white;
    @apply py-2 px-4 rounded-full inline-block w-auto font-bold;
    background: black;
    &:disabled {
      background: #ddd !important;
      color: rgba(0, 0, 0, 0.7);
      cursor: default;
    }
    label {
      @apply font-bold;
    }
  }
  textarea {
    @apply border p-3 w-full mt-2;
    height: 200px;
  }
}
.formulate-input-errors {
  background: RGBA(252, 13, 27, 0.2);
  @apply py-3 px-3 font-bold mt-3 w-2/3;
  color: red;
  font-size: 13px;
  border: 2px solid RGBA(252, 13, 27, 0.6);
}

@keyframes slideUpImg {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.tag {
  @apply relative inline-flex;
  white-space: nowrap;
  img {
    float: left;
  }
  &:hover {
    cursor: pointer;
  }
}

.header_text {
  @apply w-2/3 my-2 text-lg;
}

.sliced {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  @apply h-full flex items-center relative;
  background: rgba(255, 255, 255, 0.2);
  width: 30%;
  .logo {
    position: absolute;
    width: 100%;
    left: 0;
    background-size: 50% !important;
    background-repeat: no-repeat !important;
    background-position: 45% 60% !important;
    opacity: 0.6;
    top: 0;
    height: 100%;
    animation: slideUpImg 0.5s ease forwards;
  }
  overflow: hidden;
  height: 100%;
  padding-left: 10rem;
  img {
    position: absolute;

    width: 350px;
    min-width: 350px;
    top: 18%;
    right: 90px;
    opacity: 0.8;
    height: auto;
  }
}

header.project {
  @apply flex  items-center;
  min-height: 450px;
  background: rgb(250, 250, 250) !important;
  height: 500px;
  .wrapper {
    position: relative;
    width: 87.5% !important;
    margin-left: 12.5% !important;
    z-index: 99999;
    max-width: 1536px;
    @apply flex items-center mx-auto h-full;
    .text {
      @apply mr-0;
      width: 53%;
    }
    p {
      @apply text-lg font-normal pr-14 w-full;
    }
  }
  .sliced {
    width: 47%;
  }
  h2 {
    @apply flex text-3xl items-center font-bold pb-4 mb-5;
    border-color: rgba(0, 0, 0, 0.2);
    color: var(--color);
    img {
      height: 65px;
      width: auto;
      @apply mr-3;
    }
  }
  .content {
    z-index: 9999;
  }
}

.button_list {
  @apply inline-flex mt-5 border-t;
  border-color: rgba(0, 0, 0, 0.1);
  flex-shrink: 0;

  .button {
    @apply text-lg inline-flex border-l items-center mr-4 py-4 font-bold text-base;
    color: black !important;
    white-space: nowrap;
    border-color: rgba(0, 0, 0, 0.1);
    &:first-child {
      border: none;
    }
    &.subscribe {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 31.3'%3E%3Cpath fill='black' d='M12.5 4a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012.5 4zm0 16a7.5 7.5 0 117.5-7.5 7.5 7.5 0 01-7.5 7.5zm3.5-7.5a.5.5 0 01-.5.5H13v2.5a.5.5 0 01-1 0V13H9.5a.5.5 0 010-1H12V9.5a.5.5 0 011 0V12h2.5a.5.5 0 01.5.5z'/%3E%3C/svg%3E%0A")
        no-repeat 0px 14px;
      background-size: 30px;
      padding-left: 35px;
    }
    &.forum {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 125'%3E%3Cpath stroke='black' stroke-width='1' fill='black' d='M81.4 38c-2.8-15.2-18.9-26.6-38-26.6C22.2 11.4 5 25.4 5 42.8a27 27 0 004.8 15.1L5.2 72a3.2 3.2 0 004 4l16.2-5.4a44.4 44.4 0 007.5 2.4c5.7 8.4 16.6 14 29 14a39.2 39.2 0 0015.4-3L91 88.4a3 3 0 001 .2 3 3 0 002.8-4L91 73a23.3 23.3 0 004-13c0-9-5.4-16.9-13.6-21.8zm-55 28.5l-.7-.3-16.2 5.3 4.7-14.2-.6-.9A23.1 23.1 0 019 42.8c0-15.1 15.5-27.4 34.4-27.4 15.8 0 29.3 8.5 33.3 20.3A39 39 0 0061.9 33c-18.2 0-33 12.1-33 27a22.5 22.5 0 001.5 8.2 39.2 39.2 0 01-4-1.6zm64 17.5L77 79.6l-.8.3a35 35 0 01-14.4 3c-16 0-29-10.3-29-23s13-23 29-23 29 10.3 29 23a19.4 19.4 0 01-3.8 11.5l-.6.8z'/%3E%3C/svg%3E%0A")
        no-repeat 15px 16px;
      background-size: 24px;
      padding-left: 50px;
    }
    &.contact {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 32 40'%3E%3Cpath fill='black' d='M16 7.2a8.8 8.8 0 000 17.6.8.8 0 000-1.5 7.3 7.3 0 117.3-7.3 2.7 2.7 0 01-2.7 2.7h-.4V16a4.2 4.2 0 10-4.2 4.2 4.2 4.2 0 002.7-1v.3a.8.8 0 00.8.7h1a4.2 4.2 0 004.3-4.2A8.8 8.8 0 0016 7.2zm0 11.5a2.7 2.7 0 112.7-2.7 2.7 2.7 0 01-2.7 2.7z'/%3E%3C/svg%3E%0A")
        no-repeat 8px 13px;
      background-size: 35px;
      padding-right: 0;
      padding-left: 45px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

header.page {
  position: relative;
  min-height: 340px;
  @apply flex  items-center;
  padding-top: 150px;
  video {
    position: absolute;
    top: 0;
    opacity: 0.7;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 400px;
  }
  .bgImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
    opacity: 0.5;
    left: 0;
    top: 0;
  }

  .content {
    @apply relative mx-auto text-left mx-auto flex flex-col justify-end items-start;
    z-index: 10;
    bottom: 40px;
    max-width: 1020px;
  }
  .links {
    @apply mt-6 inline-block relative;
    z-index: 9999;
    .button {
      @apply text-white font-normal py-3 text-base font-bold mr-4;
      font-size: 14px;
      background: #ddd;
      border-left: 3px solid rgba(0, 0, 0, 0.05);
    }
  }
  .page_links {
    @apply mb-4 flex border-t relative;
    z-index: 9999;
    border-color: rgba(0, 0, 0, 0.1);
    .button {
      @apply mr-4 underline py-4 pb-3 font-bold text-lg;
      color: white !important;
      &:last-child {
        border: none;
      }
      &:first-child {
      }
    }
  }
}

header {
  .register {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  header {
    .tag {
      @apply mb-4 mt-12 text-base;
    }
    .header_text {
      @apply w-full mt-2 text-base;
    }
    .links {
      @apply mt-2;
      a {
        white-space: nowrap;
      }
    }
    .register {
      display: block;
      @apply rounded-full px-4 py-2 inline-block my-2 font-bold;
      border: 2px solid var(--color);
    }
    .content {
      width: 85%;
      max-width: 85%;
      h2 {
        @apply text-xl font-bold mt-3 mb-2;
        color: var(--color);
      }
      h1 {
        @apply text-xl;
      }
    }
    &.page.image {
      @apply flex flex-col items-start justify-start;
      .content {
        width: 100%;
        @apply px-8;
      }
      .logos {
        z-index: 99999;
        color: white;
        position: relative !important;
        bottom: 0px;
        right: 0;
        left: 40px;
        img {
          width: auto;
          height: 120px;
        }
      }
    }
    &.event {
      @apply mb-4;
      .image {
        width: 100%;
        top: 0;
        left: 0;
        height: 200px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
      }
      .wrapper {
        width: 100%;

        .text {
          width: 100%;
          margin-top: 210px;
          @apply pb-6;
          h2 {
            @apply text-2xl mb-3;
          }
          .scrollactive-nav {
            a {
              color: white;
              border-color: white !important;
            }
          }
        }
      }
    }
    &.project {
      height: auto;
      .wrapper {
        @apply flex-col;
        width: 83% !important;
        margin: 0 auto !important;
        padding-right: 0;
        .text {
          width: 100%;

          @apply pt-6 pb-4;
          p {
            padding-right: 0;
            @apply leading-normal;
          }
        }
      }
      .sliced {
        display: none;
        img {
          height: 230px;
          top: 0px;
          right: 10%;
          opacity: 0.6;
        }
      }
      .content {
        p {
          @apply w-full text-base;
        }
        h2 {
          color: white;
          @apply pt-6;
          img {
            height: 40px;
          }
        }
        .button_list {
          @apply flex-col;
          .button {
            @apply border-b text-base;
            border-color: rgba(255, 255, 255, 0.1);
            border-left: none;
            padding-left: 0px;
            background: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  header.project {
    .wrapper {
      .sliced {
        img {
          min-width: 0;
          height: 120%;
          top: 0px;
          right: 30px;
          width: auto;
          opacity: 0.5 !important;
          animation: none !important;
        }
      }
    }
  }
}
</style>
